<template>
  <div id="app">
    <label>
      <select v-model="year" id="icm-years-select">
        <option value="null" disabled>Select Year</option>
        <option v-for="year in years" :value="year.year" :key="year.year">
          {{ year.year }}
        </option>
      </select>
    </label>
    <label>
      <select v-model="make" id="icm-makes-select" :disabled="!makes.length">
        <option value="null" disabled>Select Make</option>
        <option v-for="make in makes" :value="make.makeid" :key="make.makeid">
          {{ make.make }}
        </option>
      </select>
    </label>
    <label>
      <select v-model="model" id="icm-models-select" :disabled="!models.length">
        <option value="null" disabled>Select Model</option>
        <option v-for="model in models" :value="model.modelid" :key="model.modelid">
          {{ model.model }}
        </option>
      </select>
    </label>
    <label>
      <select v-model="drivebody" id="icm-drivebodies-select" :disabled="!drivebodies.length">
        <option value="null" disabled>Select Drive/Body</option>
        <option v-for="drivebody in drivebodies" :value="drivebody.strDriveBody" :key="drivebody.strDriveBody">
          {{ drivebody.strDisplayBody }}
        </option>
      </select>
    </label>
    <label>
      <select v-model="submodel" id="icm-submodels-select" :disabled="!submodels.length">
        <option value="null" disabled>Select Sub Model</option>
        <option v-for="submodel in submodels" :value="submodel.submodelid" :key="submodel.submodelid">
          {{ submodel.submodel }}
        </option>
      </select>
    </label>
    <label>
      <select v-model="size" id="icm-sizes-select" :disabled="!sizes.length">
        <option value="null" disabled>Select Size</option>
        <option v-for="size in sizes" :value="size.sizeid" :key="size.sizeid">
          {{ size.fitmentType }} {{ size.size }}
        </option>
      </select>
    </label>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'app',
  /*
  components: {
    HelloWorld
  } */
  data() {
    return {
      selected: null
    };
  },
  computed: {
    year: {
      get() {
        return this.selectedYear;
      },
      set(year) {
        this.setYear(year);
      }
    },
    make: {
      get() {
        return this.selectedMake;
      },
      set(make) {
        this.setMake(make);
        // this.$store.commit('SELECT_MAKE', value);
      }
    },
    model: {
      get() {
        return this.selectedModel;
      },
      set(model) {
        this.setModel(model);
      }
    },
    drivebody: {
      get() {
        return this.selectedDriveBody;
      },
      set(drivebody) {
        this.setDriveBody(drivebody);
      }
    },
    submodel: {
      get() {
        return this.selectedSubModel;
      },
      set(submodel) {
        this.setSubModel(submodel);
        //this.$store.commit('SELECT_SUBMODEL', value);
      }
    },
    size: {
      get() {
        return this.selectedSize;
      },
      set(size) {
        this.setSize(size);
        // this.$store.commit('SELECT_SIZE', value);
      }
    },
    ...mapState([
      'years',
      'makes',
      'models',
      'drivebodies',
      'submodels',
      'sizes',
      'selectedYear',
      'selectedMake',
      'selectedModel',
      'selectedDriveBody',
      'selectedSubModel',
      'selectedSize'
    ])
  },
  methods: {
    ...mapActions([
      'fetchYears',
      'setYear',
      'setMake',
      'setModel',
      'setDriveBody',
      'setSubModel',
      'setSize'
    ])
  },
  created() {
    this.fetchYears();
  }
};
</script>

<style lang="scss">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
