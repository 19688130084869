import Vue from 'vue';
import Vuex from 'vuex';
import { initFetcher, Vehicles } from '@/lib/IConfiguratorAPI';

Vue.use(Vuex);

/** @namespace make.makeid **/
/** @namespace model.modelid **/
/** @namespace drivebody.strDriveBody **/
/** @namespace submodel.submodelid **/
/** @namespace size.sizeid **/
export default function makeStore({ selectionCallback, configuratorId }) {
  if (!selectionCallback) {
    selectionCallback = selection => selection;
  }

  initFetcher(configuratorId);

  const state = () => ({
    years: [],
    makes: [],
    models: [],
    drivebodies: [],
    submodels: [],
    sizes: [],
    selectedYear: null,
    selectedMake: null,
    selectedModel: null,
    selectedDriveBody: null,
    selectedSubModel: null,
    selectedSize: null
  });

  const getters = {
    selectedYear: state => state.selectedYear,
    selectedMake: state => state.selectedMake,
    selectedModel: state => state.selectedModel,
    selectedDriveBody: state => state.selectedDriveBody,
    selectedSubModel: state => state.selectedSubModel,
    selectedSize: state => state.selectedSize,
    selectedVehicle: state => ({
      year: state.years.find(year => year.year === state.selectedYear),
      make: state.makes.find(make => make.makeid === state.selectedMake),
      model: state.models.find(model => model.modelid === state.selectedModel),
      drivebody: state.drivebodies.find(
        drivebody => drivebody.strDriveBody === state.selectedDriveBody
      ),
      submodel: state.submodels.find(
        submodel => submodel.submodelid === state.selectedSubModel
      ),
      size: state.sizes.find(size => size.sizeid === state.selectedSize),
      availableYears: state.years,
      availableMakes: state.makes,
      availableModels: state.models,
      availableDriveBodies: state.drivebodies,
      availableSubModels: state.submodels,
      availableSizes: state.sizes
    })
  };

  const actions = {
    async fetchYears({ commit }) {
      try {
        const response = await Vehicles.getYears();
        commit('UPDATE_YEARS', response.data.years);
        return response.data.years;
      } catch (err) {
        throw err;
      }
    },
    async fetchMakes({ commit, getters }) {
      try {
        const response = await Vehicles.getMakes(getters.selectedYear);
        commit('UPDATE_MAKES', response.data.makes);
      } catch (err) {
        throw err;
      }
    },
    async fetchModels({ commit, getters }) {
      try {
        const response = await Vehicles.getModels(
          getters.selectedYear,
          getters.selectedMake
        );
        commit('UPDATE_MODELS', response.data.models);
      } catch (err) {
        throw err;
      }
    },
    async fetchDriveBodies({ commit, getters }) {
      try {
        const response = await Vehicles.getDriveBody(
          getters.selectedYear,
          getters.selectedMake,
          getters.selectedModel
        );
        commit('UPDATE_DRIVEBODIES', response.data.drivebodies);
      } catch (err) {
        throw err;
      }
    },
    async fetchSubModels({ commit, getters }) {
      try {
        const response = await Vehicles.getSubModels(
          getters.selectedYear,
          getters.selectedMake,
          getters.selectedModel,
          getters.selectedDriveBody
        );
        commit('UPDATE_SUBMODELS', response.data.submodels);
      } catch (err) {
        throw err;
      }
    },
    async fetchSizes({ commit, getters }) {
      try {
        const response = await Vehicles.getSizes(
          getters.selectedYear,
          getters.selectedMake,
          getters.selectedModel,
          getters.selectedDriveBody,
          getters.selectedSubModel
        );
        commit('UPDATE_SIZES', response.data.sizes);
      } catch (err) {
        throw err;
      }
    },
    async setYear({ commit, dispatch, getters }, year) {
      try {
        commit('SELECT_YEAR', year);
        dispatch('fetchMakes');
        selectionCallback(getters.selectedVehicle);
      } catch (err) {
        throw err;
      }
    },
    async setMake({ commit, dispatch, getters }, make) {
      try {
        commit('SELECT_MAKE', make);
        dispatch('fetchModels');
        selectionCallback(getters.selectedVehicle);
      } catch (err) {
        throw err;
      }
    },
    async setModel({ commit, dispatch, getters }, model) {
      try {
        commit('SELECT_MODEL', model);
        dispatch('fetchDriveBodies');
        selectionCallback(getters.selectedVehicle);
      } catch (err) {
        throw err;
      }
    },
    async setDriveBody({ commit, dispatch, getters }, drivebody) {
      try {
        commit('SELECT_DRIVEBODY', drivebody);
        dispatch('fetchSubModels');
        selectionCallback(getters.selectedVehicle);
      } catch (err) {
        throw err;
      }
    },
    async setSubModel({ commit, dispatch, getters }, submodel) {
      try {
        commit('SELECT_SUBMODEL', submodel);
        dispatch('fetchSizes');
        selectionCallback(getters.selectedVehicle);
      } catch (err) {
        throw err;
      }
    },
    async setSize({ commit, getters }, size) {
      try {
        commit('SELECT_SIZE', size);
        selectionCallback(getters.selectedVehicle);
      } catch (err) {
        throw err;
      }
    }
  };

  const mutations = {
    UPDATE_YEARS(state, years) {
      state.years = years;
    },
    UPDATE_MAKES(state, makes) {
      state.makes = makes;
    },
    UPDATE_MODELS(state, models) {
      state.models = models;
    },
    UPDATE_DRIVEBODIES(state, drivebodies) {
      state.drivebodies = drivebodies;
    },
    UPDATE_SUBMODELS(state, submodels) {
      state.submodels = submodels;
    },
    UPDATE_SIZES(state, sizes) {
      state.sizes = sizes;
    },
    SELECT_YEAR(state, year) {
      state.selectedYear = year;

      state.makes = [];
      state.models = [];
      state.drivebodies = [];
      state.submodels = [];
      state.sizes = [];
      state.selectedMake = null;
      state.selectedModel = null;
      state.selectedDriveBody = null;
      state.selectedSubModel = null;
      state.selectedSize = null;
    },
    SELECT_MAKE(state, make) {
      state.selectedMake = make;

      state.models = [];
      state.drivebodies = [];
      state.submodels = [];
      state.sizes = [];
      state.selectedModel = null;
      state.selectedDriveBody = null;
      state.selectedSubModel = null;
      state.selectedSize = null;
    },
    SELECT_MODEL(state, model) {
      state.selectedModel = model;

      state.drivebodies = [];
      state.submodels = [];
      state.sizes = [];
      state.selectedDriveBody = null;
      state.selectedSubModel = null;
      state.selectedSize = null;
    },
    SELECT_DRIVEBODY(state, drivebody) {
      state.selectedDriveBody = drivebody;

      state.submodels = [];
      state.sizes = [];
      state.selectedSubModel = null;
      state.selectedSize = null;
    },
    SELECT_SUBMODEL(state, submodel) {
      state.selectedSubModel = submodel;

      state.sizes = [];
      state.selectedSize = null;
    },
    SELECT_SIZE(state, size) {
      state.selectedSize = size;
    }
  };
  return new Vuex.Store({
    state,
    getters,
    actions,
    mutations
  });
}
